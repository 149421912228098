<script setup>
import { onMounted, ref, watch } from "vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/vue";
import axios from "axios";
import { debounce } from "lodash";

const emits = defineEmits(["select:fleet"]);
const props = defineProps({
    initialValue: {
        type: Object,
        default: null,
    },
    label: {
        type: String,
        default: "Select Fleet",
    },
    regionId: {
        type: [Number, Boolean],
        default: false,
    },
});

const fleet = ref([]);

const query = ref("");
const selectedFleet = ref(props.initialValue);

const getFleetList = debounce(async () => {
    axios
        .post("/api/search/fleet", {
            query: query.value,
        })
        .then((response) => {
            fleet.value = response.data;
        });
}, 500);

watch(query, () => {
    getFleetList();
});

watch(selectedFleet, (value) => {
    emits("select:fleet", value.id);
});

onMounted(() => {
    getFleetList();
});
</script>

<template>
    <Combobox v-model="selectedFleet" as="div" @update:model-value="query = ''">
        <ComboboxLabel
            class="block text-sm font-medium leading-6 text-gray-900"
        >
            {{ label }}
        </ComboboxLabel>
        <div class="relative mt-0">
            <ComboboxInput
                :display-value="(fleet) => fleet?.label"
                class="w-full rounded-md border-0 bg-white pt-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-ha-300 sm:text-sm sm:leading-6"
                @blur="query = ''"
                @change="query = $event.target.value"
            />
            <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            >
                <ChevronUpDownIcon
                    aria-hidden="true"
                    class="h-5 w-5 text-gray-400"
                />
            </ComboboxButton>

            <ComboboxOptions
                v-if="fleet.length > 0"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
                <ComboboxOption
                    v-for="vehicle in fleet"
                    :key="vehicle.id"
                    v-slot="{ active, selected }"
                    :value="vehicle"
                    as="template"
                >
                    <li
                        :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-ha-300 text-black' : 'text-gray-900',
                        ]"
                    >
                        <span
                            :class="[
                                'block truncate',
                                selected && 'font-semibold',
                            ]"
                        >
                            {{ vehicle.label }}
                        </span>

                        <span
                            v-if="selected"
                            :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-black' : 'text-ha-300',
                            ]"
                        >
                            <CheckIcon aria-hidden="true" class="h-5 w-5" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
        <button
            class="w-full flex justify-end text-right text-xs font-light text-blue-500 underline lowercase"
            type="button"
            @click="selectedFleet = 0"
        >
            <span>clear</span>
        </button>
    </Combobox>
</template>

<style lang="scss" scoped></style>
